import React, { useState } from "react";
import csvToArray from "../helper/csvToArray";
import EtherService from "../services/EtherService";
import { useMetaMask } from "metamask-react";
import TokenService from "../services/TokenService";
import { isEmpty } from "lodash";
import ButtonWithLoading from "./ButtonWithLoading";

const LeftSide = ({ setTableData, tableData, isEther, tokenDetails }) => {
  const { account, chainId } = useMetaMask();
  const [error, setError] = useState("");
  const [res, setRes] = useState("");
  const [approved, setApproved] = useState(false);
  const [approvedResponse, setApprovedResponse] = useState({});
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  const address = tableData?.map((list) => list[0]);
  const values = tableData?.map((list) => list[1]);

  const handleResponse = (error, data) => {
    if (error) {
      setError(error.message);
      setIsLoading2(false)
    } else {
      setError("");
      setRes(JSON.stringify(data, null, 2));
      setIsLoading2(false)
    }
  };

  const reset = () => {
    setError("");
    setRes("");
    setApproved(false);
    setApprovedResponse({});
  };

  const disperseEther = () => {
    reset();
    setIsLoading2(true)
    EtherService.disperseEther(
      { chainId, address, values },
      handleResponse,
    );
  };

  const disperseToken = () => {
    reset();
    setIsLoading2(true)
    TokenService.disperseToken(
      {
        chainId,
        tokenAddress: tokenDetails.address,
        address,
        values,
        decimals: tokenDetails.decimals,
      },
      handleResponse,
    );
  };

  const approveToken = () => {
    setIsLoading1(true);
    TokenService.approveToken(tokenDetails.contract, chainId)
    .then((res) => {
      setApproved(true);
      setApprovedResponse(res);
      setIsLoading1(false);
    })
    .catch((e) => {
      setApproved(false);
      setApprovedResponse(e.message);
      setIsLoading1(false);
    });
};

  const revokeToken = () => {
    setIsLoading3(true);
    TokenService.revokeToken(tokenDetails.contract, chainId)
    .then((res) => {
      setApproved(false);
      setApprovedResponse({})
      setIsLoading3(false);
    })
    .catch((e) => {
      setApproved(false);
      setApprovedResponse(e);
      setIsLoading3(false);
    })
  }

  const getFullArray = (textareaData, fileData) => {
    if (textareaData.value) {
      setTableData(csvToArray(textareaData.value));
    }
  };

  const convertTable = (e) => {
    if(isEther){
      if (e.keyCode === 13) {
        e.preventDefault();
        disperseEther()
      }
    }
    console.log(e);
    const csvTextareaRef = document.getElementById("csvTextarea");
    const csvFileRef = document.getElementById("csvFile");
    getFullArray(csvTextareaRef, csvFileRef);
  };

  const obj = {}

  if(isEther){
    obj.onKeyDown = convertTable
  } else {
    obj.onChange = convertTable
  }

  return (
    <div className="col-lg-6 mt-3">
      <div>
        <textarea
          className="form-control"
          id="csvTextarea"
          style={{ height: "20vh" }}
          placeholder={"0x4ba6530c62deb21644fed58a3f12d562614c4fe7,1.27"}
          {...obj}
        ></textarea>
      </div>
      {tableData && (
        <>
          <div className="mt-3">
            {
              !isEther && !isEmpty(tokenDetails) && isEmpty(approvedResponse) &&
              <>
                  <ButtonWithLoading
                  btnName="Approve"
                  onClick={approveToken}
                  isLoading={isLoading1}
                />
              </>
            }
            {
              !isEther && !isEmpty(tokenDetails) && !isEmpty(approvedResponse) && isEmpty(approvedResponse.error) &&
              <>
                <ButtonWithLoading
                  btnName="Revoke"
                  onClick={revokeToken}
                  isLoading={isLoading3}
                />
              </>
            }
           <ButtonWithLoading
            btnName={isEther ? "Disperse Ether" : "Disperse Token"}
            onClick={isEther ? disperseEther : disperseToken}
            style={{
              backgroundColor: ((isEther
                ? false
                : !approved) || isLoading2 )
                ? "rgb(118 178 166)"
                : "rgb(6 121 98)",
              cursor: (((isEther ? false : !approved)) || isLoading2) ? "not-allowed" : "pointer",
              marginLeft: isEther ? "" : "0.7rem",
            }}
            isLoading={isLoading2}
            manualDisabled={isEther ? false : !approved}
            />
          </div>
          {!isEmpty(approvedResponse) && !isEther && (
            <div className="mt-3">
              <pre>{JSON.stringify(approvedResponse, null, 2)}</pre>
            </div>
          )}
          <div className="d-flex" style={{ color: "red", marginTop: 20 }}>
            {error}
          </div>
          {res && (
            <>
              <div
                className="d-flex mt20"
                style={{ marginBottom: 20, marginTop: 20, color: "green" }}
              >
                Successfully Dispersed!
              </div>
              <div className="d-flex mt20">
                Response:
                <pre>{res}</pre>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LeftSide;

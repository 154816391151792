import React from "react";

const ButtonWithLoading = ({ btnName, onClick, isLoading, manualDisabled, style }) => {

  return (
    <button
      type="button"
      className="text-white fw-bold px-4 py-2 customBtn btn-success"
      style={style || {
        backgroundColor: isLoading ? "rgb(118 178 166)" : "rgb(6 121 98)",
        cursor: isLoading ? "not-allowed" : "pointer",
      }}
      onClick={() => {
        if(!manualDisabled && !isLoading){
        onClick();
        }
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        {isLoading && (
          <div className="d-flex justify-content-center me-1">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div>{btnName}</div>
      </div>
    </button>
  );
};

export default ButtonWithLoading;

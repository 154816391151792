import {ethers} from 'ethers';
import disperseABI from '../constants/disperseABI.json';
import ChainDisperseMap from '../constants/ChainDisperseMap.json';
import { isEmpty } from 'lodash';

const EtherService = {

    async disperseEther({ chainId, address, values }, cb) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contractAddress = ChainDisperseMap[chainId];
        if(isEmpty(contractAddress)) {
            cb({ message: `${chainId} Chain Id not supported` })
        }
        const etherAddresses = address.map((ad) => {
            return ethers.utils.getAddress(ad);
        }); 
        const etherValues = values.map((v) => {
            return ethers.utils.parseUnits(v, 18);
        })  
        const Contract = new ethers.Contract(contractAddress, disperseABI, signer, console.log('contract initiated'));
        const total = values.reduce((e, t) => {
            return e.add(ethers.utils.parseUnits(t, 18))
        }, ethers.constants.Zero)

        Contract.disperseEther(etherAddresses, etherValues, {
            value: total
        })
        .then((res) => {
            console.log(res)
            cb(null, res);
        })  
        .catch((e) => {
            cb(e);
            console.log(e)
        })
    }
}

export default EtherService;
import { useState } from "react";
import Ether from "./components/Ether";
import Token from "./components/Token";
import MetaMaskConnect from "./components/MetaMaskConnect";
import { useMetaMask } from "metamask-react";
import { Dropdown } from 'react-bootstrap'

function App() {
  const { status, account, chainId } = useMetaMask();

  const [etherTableData, setEtherTableData] = useState(null);
  const [tokenTableData, setTokenTableData] = useState(null);
  const [tab, setTab] = useState("ether");

  return (
    <div>
      <nav
        className="navbar navbar-light fw-bold fixed-top"
        style={{ backgroundColor: "rgb(6 121 98)" }}
      >
        <div className="container-fluid d-flex">
          <span className="mb-0 text-white fs-4" style={{ width: "7%" }}>
            Disperse
          </span>
          {status === "connected" && (
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="text-white cursor-pointer" as='div'>
              Connected
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div"><b>{account}</b> on chain ID <b>{chainId}</b></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </nav>
      <div className="row mx-2" style={{ marginTop: "4.5rem" }}>
        <MetaMaskConnect />
        {status === "connected" && (
          <>
            <ul class="nav nav-tabs">
              <li class="nav-item" onClick={() => setTab("ether")}>
                <span
                  class={`nav-link fw-bold cursor-pointer ${
                    tab === "ether" ? "active" : "text-success"
                  }`}
                >
                  Ether
                </span>
              </li>
              <li class="nav-item" onClick={() => setTab("token")}>
                <span
                  class={`nav-link fw-bold cursor-pointer ${
                    tab === "token" ? "active" : "text-success"
                  }`}
                >
                  Token
                </span>
              </li>
            </ul>
            {tab === "ether" ? (
              <Ether
                setEtherTableData={setEtherTableData}
                etherTableData={etherTableData}
              />
            ) : (
              <Token setTokenTableData={setTokenTableData} tokenTableData={tokenTableData} account={account} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default App;

import {ethers} from 'ethers';
import stABI from '../constants/StABI.json';
import mtABI from '../constants/MtABI.json';
import disperseABI from '../constants/disperseABI.json';
import ChainDisperseMap from '../constants/ChainDisperseMap.json';


const TokenService = {
    async loadToken(token,account) {
        let tokenDetails = {};
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        let et;

        try {
            et = ethers.utils.getAddress(token);
        }
        catch (e) {
            tokenDetails = {
                error: 'Invalid token Address'
            }
            return tokenDetails;
        }
        try{
            let r = new ethers.Contract(et, mtABI, provider.getSigner());
    
            tokenDetails = {
                address: et,
                contract: r,
                balance: await r.balanceOf(account),
                name: await r.name(),
                symbol: await r.symbol(),
                decimals: await r.decimals()
            }

        } catch(e) {
            console.log(e);
            try {
                var n = new ethers.Contract(et, stABI, provider.getSigner());
                tokenDetails = {
                    address: et,
                    contract: n,
                    balance: ethers.utils.parseBytes32String(await n.balanceOf(et)),
                    name: ethers.utils.parseBytes32String(await n.name()),
                    symbol: ethers.utils.parseBytes32String(await n.symbol()),
                    decimals: await n.decimals()
                }
            }
            catch(e){
                tokenDetails = {
                    error: 'Unsupported token'
                }
            }
        }

        return tokenDetails;
    },

    disperseToken({ chainId, tokenAddress, address, values, decimals }, cb) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contractAddress = ChainDisperseMap[chainId];
        const etherAddresses = address.map((ad) => {
            return ethers.utils.getAddress(ad);
        }); 
        const etherValues = values.map((v) => {
            return ethers.utils.parseUnits(v, decimals);
        })  
        const Contract = new ethers.Contract(contractAddress, disperseABI, signer, console.log('contract initiated'));
        const total = values.reduce((e, t) => {
            return e.add(ethers.utils.parseUnits(t, 18))
        }, ethers.constants.Zero)

        console.log(tokenAddress, etherAddresses, etherValues, total)

        Contract.disperseToken(tokenAddress, etherAddresses, etherValues)
        .then((res) => {
            console.log(res)
            cb(null, res);
        })  
        .catch((e) => {
            console.log(e)
            cb(e);
        })
    },

    approveToken(contract, chainId) {
       const contractId =  ChainDisperseMap[chainId];
        return contract.approve(contractId, ethers.constants.MaxUint256);
    },

    revokeToken(contract, chainId) {      
        const contractId =  ChainDisperseMap[chainId];
        return contract.approve(contractId, ethers.constants.Zero);
    }

}

export default TokenService;
import React from "react";
import { useMetaMask } from "metamask-react";

const MetaMaskConnect = () => {
  const { status, connect} = useMetaMask();

  if (status === "initializing")
    return <div>Synchronisation with MetaMask ongoing...</div>;

  if (status === "unavailable") {
    return (
      <div className="d-flex justify-content-center align-items-center unavailable-container">
        <div>
          <h2>metamask required</h2>
          <p>non-ethereum browser, consider installing metamask.</p>
        </div>
      </div>
    );
  }

  if (status === "notConnected") {
    return (
      <div className="d-flex justify-content-center align-items-center notConnected-container">
          <a href="#" className="btn btn-green btn-animate" onClick={connect}>
            Connect to MetaMask Wallet
          </a>
      </div>
    );
  }

  if (status === "connecting") return <div className="text-center text-success fs-5 fw-bold">Connecting...</div>;

  return null;
};

export default MetaMaskConnect;

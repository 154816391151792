function csvToArray(csv){

    var lines=csv.split("\n");
  
    var result = [];  

    for(var i=0;i<lines.length;i++){
        var list=lines[i].split(",");
        const filterList = list.filter(item=> item !== '\r')
        result.push(filterList);
    }
    return result;
  }

  export default csvToArray;
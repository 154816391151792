import React from "react";

const RightSide = ({ tableData }) => {

  if (!tableData) return <span />;

  return (
    <div className="col-lg-6 table-responsive mt-3">
      <table className="table table-hover table-bordered">
        <thead
          className="text-white"
          style={{ backgroundColor: "rgb(6 121 98)" }}
        >
          <tr>
            <th scope="col">Address</th>
            <th scope="col">Values</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map(
            (body) =>
              (body[0] || body[1]) && (
                <tr>
                  <td>{body[0]}</td>
                  <td>{body[1]}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RightSide;

import { isEmpty } from "lodash";
import React, { useState } from "react";
import TokenService from "../services/TokenService";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import ButtonWithLoading from './ButtonWithLoading'

const Token = ({ tokenTableData, setTokenTableData, account }) => {
  const [token, setToken] = useState("");
  const [tokenDetails, setTokenDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
 

  const handleTokenLoad = () => {
    setIsLoading(true);
    TokenService.loadToken(token, account)
      .then((tokenDetails) => {
        console.log(tokenDetails);
        setTokenDetails(tokenDetails);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setTokenDetails(e);
      });
  };

  const handleTokenChange = (e) => {
    console.log(e);
    if (e.keyCode === 13) {
      handleTokenLoad()
      return;
    }
    setToken(e.target.value);
  };

  return (
    <>
      <div className="mt-3 d-flex align-items-center w-50">
        <div class="input-group me-2">
          <span class="input-group-text" id="inputGroup-sizing-default">
            Token
          </span>
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            onKeyUp={handleTokenChange}
          />
        </div>
        <ButtonWithLoading btnName='Load' onClick={handleTokenLoad} isLoading={isLoading} />
      </div>
      {!isEmpty(tokenDetails) && (
        <div>
          <pre className="ml-4 mt-3 my-0">
            {JSON.stringify(
              {
                Name: tokenDetails.name,
                Symbol: tokenDetails.symbol,
                balance: tokenDetails.balance && tokenDetails.balance.toString(),
                decimals: tokenDetails.decimals,
                error: tokenDetails.error
              },
              null,
              2
            )}
          </pre>
          {isEmpty(tokenDetails.error) && (
            <div className="row">
              <LeftSide
                tableData={tokenTableData}
                setTableData={setTokenTableData}
                tokenDetails={tokenDetails}
              />
              <RightSide tableData={tokenTableData} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Token;

import React from "react";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";

const Ether = ({ etherTableData, setEtherTableData}) => {
  return (
    <>
      <LeftSide tableData={etherTableData} setTableData={setEtherTableData} isEther />
      <RightSide tableData={etherTableData} />
    </>
  );
};

export default Ether;
